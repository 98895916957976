import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Create and manage all of the Projects that your organisation is working on. `}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Creating Projects</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Editing Projects</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Deleting Projects</AnchorLink>
    </AnchorLinks>
    <h2>{`Creating Projects`}</h2>
    <p>{`There are 2 ways to access project creation, and both methods lead to the same project creation functionality.`}</p>
    <ol>
      <li parentName="ol">{`On the Landing Page Click the `}<inlineCode parentName="li">{`New Project`}</inlineCode>{` button.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/52_projects-new-project.png",
          "alt": "52_projects-new-project.png"
        }}></img></li>
      <li parentName="ol">{`In the Projects Tab, Click the `}<inlineCode parentName="li">{`Add New Project`}</inlineCode>{` button.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/53_projects-homescreen-new-project.png",
          "alt": "53_projects-homescreen-new-project.png"
        }}></img></li>
    </ol>
    <p>{`To add a new Project to your profile:`}</p>
    <ol>
      <li parentName="ol">{`Click either of the `}<inlineCode parentName="li">{`New Project Buttons`}</inlineCode>{` above.`}</li>
      <li parentName="ol">{`In the Create New Project window, enter the initial project information:
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/54_create-new-project.png",
          "alt": "54_create-new-project.png"
        }}></img>
        <pre parentName="li"><code parentName="pre" {...{}}>{`1. Project Name – Enter the name of your project.
2. Project Number (optional) – Enter a number for your project.
3. Description (optional) – Enter a description for your project.
5. Client Name (optional) - Enter the name of the client organisation.
6. Client Address (optional) - Enter the address of the client organisation.
7. Copyright/Notes (optional) - Enter any notes or copyright information to appear on the drawings titleblock here. 
4. Address (optional) – Enter an address for your project.
    1. Use the map search bar for an automatic location point added to the map.
    2. Manually place a map point by clicking the point tool.
    - To edit, drag and drop the point to a new area.
    3. Draw an area using the polygon tool.
    - Confirm by double-clicking or pressing enter.
    4. To delete, click the point or area, then click delete.
`}</code></pre>
      </li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Create Project`}</inlineCode>{` to save the new project to your profile.`}</li>
    </ol>
    <h2>{`Editing Projects`}</h2>
    <p>{`To Edit an existing project:`}</p>
    <ol>
      <li parentName="ol">{`Navigate to the Projects tab.`}</li>
      <li parentName="ol">{`Click on the `}<inlineCode parentName="li">{`⫶`}</inlineCode>{` icon to the right of the project you wish to edit.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/55_projects-homescreen-editing-projects.png",
          "alt": "55_projects-homescreen-editing-projects.png"
        }}></img></li>
      <li parentName="ol">{`Select `}<inlineCode parentName="li">{`Edit`}</inlineCode>{` from the menu.`}</li>
      <li parentName="ol">{`The `}<inlineCode parentName="li">{`Edit Project`}</inlineCode>{` pop-up will open.`}</li>
    </ol>
    <h2>{`Deleting Projects`}</h2>
    <p>{`To delete an existing project:`}</p>
    <ol>
      <li parentName="ol">{`Navigate to the Projects tab.`}</li>
      <li parentName="ol">{`Click on the `}<inlineCode parentName="li">{`⫶`}</inlineCode>{` icon to the right of the project you want to Edit.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/56_projects-homescreen-removing-projects.png",
          "alt": "56_projects-homescreen-removing-projects.png"
        }}></img></li>
      <li parentName="ol">{`Select `}<inlineCode parentName="li">{`Delete`}</inlineCode>{` from the menu.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      